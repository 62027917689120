<template>
  <v-container fluid class="pa-4">
    <common-error
      v-if="isError"
      @fetchData="fetchAccountInfo"
    />
    <SkelatonLoader
      v-else-if="isLoading"
    />
    <v-sheet v-else rounded class="white">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <img src="@/assets/images/fli_logo.png" class="logo-account-info mb-7" alt="" srcset="">
        </v-row>
        <v-row>
          <v-col>
            <v-progress-circular
              v-if="uploadProgress > 0"
              :rotate="360"
              :size="100"
              :width="15"
              :value="uploadProgress"
              color="primary"
              class="v-progress"
            >
              {{  Math.ceil(uploadProgress) }}
            </v-progress-circular>
            <img v-else-if="form.photoProfileUrl" :src="form.photoProfileUrl" class="imageAccount" contain>
            <img v-else src="@/assets/images/userProfile.png" class="imageAccount">
          </v-col>
          <v-col>
            <v-tooltip left fixed nudge-width="5" nudge-right="110" nudge-bottom="80">
              <template v-slot:activator="{ on, attrs }">
                <v-file-input
                  :rules="rules.photoProfileUrl"
                  :loading="uploadingImage"
                  ref="imageInput"
                  type="file"
                  accept="image/*"
                  @change="setImage"
                  v-model="imageInput"
                  prepend-icon="mdi-pencil-circle"
                  color="blue"
                  hide-input
                  class="img-account-info"
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter.native="on.mouseenter"
                  @mouseleave.native="on.mouseleave"
                />
              </template>
              <span>Foto Profil Perusahaan</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <span class="title-account-info">Personal Data</span>
        </v-row>
        <v-row class="desc-account-info">
          <v-col>
            <p class="caption grey--text ma-0">{{ $_strings.userAdmin.NAME_TABLE_HEADER_LABEL }}</p>
            <p class="mt-2">{{ form.name}}</p>
          </v-col>
          <v-col>
            <p class="caption grey--text ma-0">{{ $_strings.userAdmin.EMAIL_TABLE_HEADER_LABEL }}</p>
            <p class="mt-2">{{ form.email}}</p>
          </v-col>
        </v-row>
        <v-row class="desc-account-info">
          <v-col>
            <p class="caption grey--text ma-0">{{ $_strings.userAdmin.PHONE_INPUT_LABEL }}</p>
            <p class="mt-2">{{ form.phoneNumber}}</p>
          </v-col>
          <v-col>
            <p class="caption grey--text ma-0">{{ $_strings.userAdmin.ROLE_INPUT_LABEL }}</p>
            <p class="mt-2">{{ form.position}}</p>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import SkelatonLoader from './SkeletonLoader.vue';

export default {
  name: 'account-info',
  components: {
    SkelatonLoader,
  },
  created() {
    this.fetchAccountInfo();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      uploadingImage: false,
      valid: false,
      oldProfileUrl: '',
      form: {
        photoProfileUrl: '',
      },
      imageInput: null,
      rules: {
        photoProfileUrl: [
          (v) => !!this.form.photoProfileUrl || this.$_strings.messages.validation.REQUIRED('Photo profil'),
        ],
      },
      uploadProgress: 0,
    };
  },
  methods: {
    dayjs,
    fileUploadValidation(event) {
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > 512000) {
        this.$dialog.notify.error('Ukuran gambar maksimum 500kb.');
        return false;
      }
      return true;
    },
    setImage(file) {
      if (!file) return;
      if (this.fileUploadValidation(file)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadImage(e.target.result, file.name);
        };
        reader.readAsDataURL(file);
      }
    },
    uploadImage(base64Image, fileName) {
      this.uploadingImage = true;
      const extension = fileName.split('.').pop();
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId, email } = dataUser;
      const replaceEmail = `${email.replace(/[@.]/g, '_')}_${dayjs().unix()}`;
      const newFileName = replaceEmail.concat(`.${extension}`);
      const filePath = `user/${companyId}/`;
      const self = this;
      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          self.uploadProgress = percentCompleted;
        },
      };
      this.uploadProgress = 1;
      this.oldProfileUrl = this.form.photoProfileUrl;
      this.$_services.uploads.uploadImage(base64Image, newFileName, filePath, config)
        .then((result) => {
          this.form.photoProfileUrl = result.data.url;
          this.$store.dispatch('user/setMyUserInfo', {
            ...dataUser,
            imageUrl: result.data.url,
          });
          this.submit();
        }).finally(() => {
          this.uploadingImage = false;
          this.uploadProgress = 0;
        });
    },
    fetchAccountInfo() {
      const dataUser = this.$store.state.user.myUserInfo;
      this.isLoading = true;
      this.isError = false;
      this.$_services.userAdmins.accountInfo(dataUser.companyUserId)
        .then((res) => {
          this.form = res.data;
        }).catch(() => {
          this.isError = true;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    submit() {
      const dataUser = this.$store.state.user.myUserInfo;
      this.$root.$loading.show();
      this.$_services.userAdmins.updateAccountInfo(this.form, dataUser.companyUserId)
        .then(() => {
          if (this.oldProfileUrl && (this.form.photoProfileUrl !== this.oldProfileUrl)) {
            const payload = {
              url: this.oldProfileUrl,
            };
            this.$_services.uploads.deleteFile(payload);
          }
          this.$dialog.notify.success('Berhasil memperbarui foto profil');
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-image {
    color: #b5b5b5;
  }
  .custom-image::-webkit-file-upload-button {
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    background-color: white;
    color: #2c59c2;
    border-width: 1px;
    border-color: #2c59c2;
  }
  .imageAccount {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-left: 90%;
      margin-right: auto;
      border-radius: 50%;
      display: inline;
  }
  .v-progress {
    margin-left: 90%;
  }
  .img-account-info {
      margin-left: 18px;
      margin-top: 20px;
      color: #2c59c2;
      width: 5px;
  .v-icon.v-icon.v-icon--link {
      color: #2c59c2;
    }
  }
  .mdi-pencil-circle {
      color: #2c59c2;
  }
  .title-account-info{
    margin-left: 21.5%;
    margin-right: 20%;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .desc-account-info{
    margin-left: 20%;
    margin-right: 20%;
    font-size: 14px;
  }
  .logo-account-info{
    width: 15s0px;
    height: 40px;
    margin: 25px 30px 30px 32px;
  }
  .img-account-info {
      margin-left: 18px;
      margin-top: 20px;
      color: #2c59c2;
  .v-icon.v-icon.v-icon--link {
      color: #2c59c2;
    }
  }
</style>
